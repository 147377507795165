<template>
  <div>
    <div :class="containerClass">
      <div class="back-result-lg font-weight-bold mt-5">
        <a href="#" @click.prevent="goBack()" style="text-decoration: underline" class="mr-2">
          <span class="material-icons icon-32pt">navigate_before</span>
        </a>
      </div>

      <div v-if="isPublic" class="d-flex justify-content-center mb-3">
        <img
          class="mr-2"
          :src="
            get(scholarship, 'posted_by_org.logo_url')
              ? get(scholarship, 'posted_by_org.logo_url')
              : get(scholarship, 'employers[0].logo_url')
          "
          width="150px"
          height="auto"
        />
        <div style="font-size: 2rem" :class="{ 'd-flex align-items-center ': isPublic }">
          <b :class="{ 'text-center': isPublic }">{{ get(scholarship, 'title') }} </b>
        </div>
      </div>

      <div style="font-size: 2rem" v-else>
        <b>{{ get(scholarship, 'title') }} </b>
      </div>

      <div class="mt-2" :class="{ 'd-flex justify-content-center': isPublic }" style="font-size: 1.1rem">
        <b-skeleton-wrapper :loading="isLoading" class="w-100">
          <template #loading>
            <div :class="{ 'd-flex justify-content-center': isPublic }">
              <b-skeleton width="35%"></b-skeleton>
            </div>
          </template>
          <span class="mr-1">Offered By</span
          ><b class="text-dark">{{
            get(scholarship, 'posted_by_org.name')
              ? get(scholarship, 'posted_by_org.name')
              : get(scholarship, 'employers[0].name') || 'None'
          }}</b>
        </b-skeleton-wrapper>
      </div>

      <page-separator title="" />
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="row">
            <div class="col-lg-2">
              <div class="d-flex justify-content-center">
                <a
                  href="#"
                  @click.prevent
                  class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                  style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                >
                  <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                </a>
              </div>

              <div class="mb-3">
                <b-skeleton type="button" width="100%"></b-skeleton>
              </div>
            </div>

            <div class="col-lg-10 d-flex mt-3">
              <div class="flex" style="max-width: 100%">
                <h3><b-skeleton width="100%"></b-skeleton></h3>
                <div class="mb-0 mt-3" style="text-transform: none">
                  <b-skeleton width="100%"></b-skeleton>
                  <b-skeleton width="55%"></b-skeleton>
                  <b-skeleton width="70%"></b-skeleton>
                  <b-skeleton width="65%"></b-skeleton>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="row">
          <div class="col-lg-2">
            <div class="d-flex justify-content-center">
              <a
                href="#"
                @click.prevent
                class="avatar avatar-xxl overlay js-overlay overlay--primary rounded-circle p-0 o-hidden d-flex justify-content-center align-items-center mb-3"
                style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
              >
                <img
                  class="avatar-img m-0"
                  :src="get(scholarship, 'image_url') || DefaultAvatar"
                  width="160"
                  height="auto"
                />
              </a>
            </div>

            <!-- :to="{
                  name: 'program-detail',
                  params: { slug: this.$route.query.program },
                  query: { tab: 'upcoming-classes' },
                }" -->
            <div class="mb-3">
              <b-btn
                v-if="get(scholarship, 'scholarship_type') === SPONSORSHIP_SUBMISSION_TYPES.IN_APP_SUBMISSION"
                variant="light"
                :disabled="isLoading"
                @click.prevent="() => (showTermsModal = true)"
                class="mt-2 w-100 mr-4 btn-normal"
              >
                <md-icon class="icon-16pt mr-1">launch</md-icon>Apply Now
              </b-btn>
              <b-btn
                v-else-if="get(scholarship, 'external_link')"
                variant="light"
                :disabled="isLoading"
                class="mt-2 w-100 mr-4 btn-normal"
                :href="getClickableLink(get(scholarship, 'external_link'))"
              >
                <md-icon class="icon-16pt mr-1">launch</md-icon>Apply Now
              </b-btn>
            </div>
          </div>

          <div class="col-lg-10 d-flex">
            <div class="flex" style="max-width: 100%">
              <div class="mb-0">
                <div
                  class="mb-1"
                  style="font-size: 15px"
                  v-if="get(scholarship, 'min_amount') && get(scholarship, 'max_amount')"
                >
                  <strong class="text-primary mr-1">Amount: </strong>
                  ${{ $n(get(scholarship, 'min_amount')) }} - ${{ $n(get(scholarship, 'max_amount')) }}
                </div>

                <div class="mb-1" style="font-size: 15px" v-if="get(scholarship, 'deadline')">
                  <strong class="text-primary mr-1">Deadline: </strong
                  >{{ formatDateSimple(get(scholarship, 'deadline')) }}
                </div>

                <div class="mb-1" style="font-size: 15px">
                  <strong class="text-primary mr-1">Category: </strong
                  >{{ $t(`sponsorshipCategories.${scholarship.categories}`) }}
                </div>

                <div
                  class="mb-1"
                  v-if="
                    get(scholarship, 'address') ||
                    get(scholarship, 'region') ||
                    get(scholarship, 'city') ||
                    get(scholarship, 'state') ||
                    get(scholarship, 'zipcode') ||
                    get(scholarship, 'country')
                  "
                  style="font-size: 15px"
                >
                  <strong class="text-primary mr-1">Address: </strong>
                  {{
                    computeAddress(
                      scholarship,
                      ['address', 'region', 'city', 'state', 'zipcode', 'country'],
                      (stateKey = 'state'),
                      (zipcodeKey = 'zipcode')
                    )
                  }}
                </div>

                <div class="mb-1" v-if="get(scholarship, 'work_commitment')" style="font-size: 15px">
                  <strong class="text-primary mr-1">Work Commitment: </strong
                  >{{ get(scholarship, 'work_commitment') }} Months
                </div>

                <div class="mb-1" v-if="get(scholarship, 'type')" style="font-size: 15px">
                  <strong class="text-primary mr-1">Type: </strong
                  >{{ $t(`jobWorkTypeOpts.${get(scholarship, 'type')}`) }}
                </div>

                <div class="mb-1" v-if="get(scholarship, 'schedule')" style="font-size: 15px">
                  <strong class="text-primary mr-1">Schedule: </strong
                  >{{ $t(`jobScheduleOpts.${get(scholarship, 'schedule')}`) }}
                </div>

                <div
                  v-if="get(scholarship, 'minimum_pay') && get(scholarship, 'maximum_pay')"
                  class="mb-1"
                  style="font-size: 15px"
                >
                  <strong class="text-primary mr-1">Pay: </strong>
                  ${{ $n(get(scholarship, 'minimum_pay')) }} - ${{ $n(get(scholarship, 'maximum_pay')) }}
                </div>

                <div v-if="get(scholarship, 'pay_rate')" class="mb-1" style="font-size: 15px">
                  <strong class="text-primary mr-1">Pay Rate: </strong
                  >{{ $t(`jobPayRateOpts.${get(scholarship, 'pay_rate')}`) }}
                </div>

                <div v-if="get(scholarship, 'benefits.length')" class="mb-1" style="font-size: 15px">
                  <strong class="text-primary mr-1">Benefits: </strong>
                  <span v-for="(benefit, index) in get(scholarship, 'benefits')" :key="benefit + index">
                    <span
                      v-if="
                        get(scholarship, 'benefits.length') >= 2 && get(scholarship, 'benefits.length') - 1 <= index
                      "
                    >
                      and</span
                    >
                    {{ benefit === 'other' ? get(scholarship, 'other_benefits') : $t(`benefitOptions.${benefit}`) }}
                    <span v-if="get(scholarship, 'benefits.length') - 2 > index">,</span>
                  </span>
                </div>

                <div
                  v-if="get(scholarship, 'career_offerings.length') || get(scholarship, 'other_career_offering')"
                  class="mb-1"
                  style="font-size: 15px"
                >
                  <strong class="text-primary mr-1">Career Offerings: </strong>
                  <span v-for="(career, index) in get(scholarship, 'career_offerings')" :key="career.name + index">
                    {{ career.name }}
                    <span v-if="get(scholarship, 'career_offerings.length') - 1 > index">,</span>
                  </span>
                  <span v-if="get(scholarship, 'career_offerings.length') > 1">and</span>

                  {{ get(scholarship, 'other_career_offering') }}
                </div>

                <div v-if="get(scholarship, 'career_offerings_details')" class="mb-1" style="font-size: 15px">
                  <strong class="text-primary mr-1">Career Offerings Details: </strong
                  >{{ get(scholarship, 'career_offerings_details') }}
                </div>

                <div style="font-size: 15px" v-html="get(scholarship, 'description')"></div>
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
    </div>
    <app-terms-modal
      :show-modal="showTermsModal"
      @close="showTermsModal = false"
      :scholarship="scholarship"
    ></app-terms-modal>
  </div>
</template>


<script>
import { get, map } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import Page from '@/components/Page.vue';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { formatDateSimple, getClickableLink, computeAddress } from '@/common/utils';
import { SPONSORSHIP_SUBMISSION_TYPES, SPONSORSHIP_CATEGORIES_TYPES } from '@/common/constants';
import AppTermsModal from './AppTermsModal.vue';

export default {
  name: 'SchDetails',

  components: {
    PageSeparator,
    AppTermsModal,
  },
  extends: Page,

  props: {
    isPublic: { type: Boolean, default: false },
  },

  data() {
    return {
      title: 'Sponsorship Details',
      DefaultAvatar,
      isLoading: false,
      isStatusUpdating: false,
      scholarship: {},
      showTermsModal: false,
      SPONSORSHIP_SUBMISSION_TYPES,
      SPONSORSHIP_CATEGORIES_TYPES,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    ...mapGetters('auth', ['getLoggedInUser']),

    eligibleSchools() {
      return get(this.scholarship, 'schools') ? map(this.scholarship.schools, 'name').join(', ') : 'None';
    },
  },

  methods: {
    ...mapActions('program', ['getScholarship']),
    ...mapActions('settings', ['setSettings']),
    get,
    getClickableLink,
    formatDateSimple,
    computeAddress,

    goBack() {
      this.$router.go(-1);
    },

    async fetchScholarship() {
      this.isLoading = true;
      try {
        const res = await this.getScholarship({ scholarship_id: this.$route.params.id });

        this.scholarship = res.data;

        this.title = this.scholarship.title;
      } catch (e) {
        this.$router.go(-1);
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },
  },

  async mounted() {
    this.setSettings({ layout: { layout: 'fixed' } });

    this.fetchScholarship();
  },
};
</script>
