<template>
  <b-modal
    ref="productDetailModal"
    hide-footer
    :title="`${product.product_title}`"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="md"
  >
    <div class="row">
      <div class="col-md-4">
        <img :src="product.product_url ? product.product_url : DefaultAvatar" class="w-100" />
      </div>
      <div class="col-md-8">
        <div class="mb-0" style="text-transform: none">
          <h3>{{ product.product_title }}</h3>
          <strong>{{ $n(product.price, 'currency', 'en-US') }}</strong>
          <div v-if="get(product, 'sizes')" class="mb-2">
            <div class="custom-select-icon" style="min-width: 50px">
              <b-form-select class="custom-select-icon__select" v-model="size" :options="sizeOptions"> ></b-form-select>
              <span class="material-icons custom-select-icon__icon">sort</span>
            </div>
          </div>

          <div class="d-flex justify-content-between">
            <span class="mt-1" style="font-size: 1.5rem">
              <a class="clickable-item" @click.prevent="decrementQuantity(product)"
                ><i class="fas fa-minus-square"></i
              ></a>
              {{ $n(quantity) }}
              <a class="clickable-item" @click.prevent="incrementQuantity(product)"
                ><i class="fas fa-plus-square"></i
              ></a>
            </span>
            <b-btn variant="light" style="width: 150px" @click.prevent="addToCart" class="btn-normal float-right">
              <span>Add To Cart</span>
            </b-btn>
          </div>

          <div class="mt-2">
            <h5 class="mb-1" style="text-transform: capitalize">Description</h5>
            <p>{{ product.product_description }}</p>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { get } from 'lodash';
export default {
  components: {},
  props: {
    showModal: { type: Boolean, default: false },
    product: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      DefaultAvatar,
      quantity: 1,
      size: null,
      sizeOptions: [{ value: null, text: 'Please Select', disabled: true }],
    };
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.productDetailModal.show();
        this.sizeOptions = this.sizeOptions.concat(this.product.sizes);
      }
    },
  },
  methods: {
    get,
    addToCart() {
      this.makeToast({ variant: 'success', msg: 'Product added successfully.' });
      this.$emit('add', this.product, this.quantity, this.size);
      this.quantity = 1;
    },
    incrementQuantity() {
      this.quantity += 1;
    },
    decrementQuantity() {
      if (this.quantity > 1) {
        this.quantity -= 1;
      }
    },
    hideModal() {
      this.quantity = 1;
      this.sizeOptions = [{ value: null, text: 'Please Select', disabled: true }];
      this.size = null;
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.productDetailModal.hide();
      this.hideModal();
    },
  },
};
</script>

<style>
</style>
