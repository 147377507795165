<template>
  <b-modal
    ref="instructorModal"
    hide-footer
    title="Class Instructors"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
  >
    <div v-if="!instructors.length" class="card-body text-center">
      <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
      <h4>No Instructors Found</h4>

      <p class="text-muted">No instructors found.</p>
    </div>
    <div class="row card-group-row mb-lg-12pt home-card">
      <div class="col-sm-12 card-group-row__col" v-for="(ins, index) in instructors" :key="ins.id">
        <instructor-card :instructor="ins" :index="index + 1" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import InstructorCard from './InstructorCard.vue';
export default {
  components: {
    InstructorCard,
  },
  props: {
    showModal: { type: Boolean, default: false },
    instructors: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        this.$refs.instructorModal.show();
      }
    },
  },
  methods: {
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.instructorModal.hide();
      this.hideModal();
    },
  },
};
</script>

<style>
</style>
