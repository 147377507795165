<template>
  <div
    class="card card-sm card--elevated p-relative overlay clickable-item"
    style="height: 92%;"
    @click.prevent="openProgramDetail()"
  >
    <span class="js-image d-flex justify-content-center clickable-item" data-position="left" :data-height="168">
      <b-badge
        variant="dark"
        class="mt-2 ml-2 d-flex justify-content-center"
        style="font-size: 0.81rem ; width:110px; position:absolute; left: 0;"
        v-if="get(program, 'prgType') === 'referred'"
      >
        Referred
      </b-badge>
      <img :src="program.image" alt="cna" class="career-card-img" />
    </span>

    <div class="card-body d-flex flex-column justify-content-center">
      <div class="card-title text-center">{{ program.title }}</div>

      <div class="text-center text-primary mt-2" v-if="get(program, 'prgType') === 'referred'">
        Offered by {{ get(program, 'school.name', 'our affiliate.') }}
      </div>

      <div class="d-flex justify-content-center mb-2">
        <b-badge variant="primary" class="mt-2 d-flex justify-content-center" style="font-size: 0.81rem ; width:110px ">
          {{ $t(`programTypeOptions.${program.type}`) }}
        </b-badge>

        <div class="ml-2" v-if="program.schedule_type == 'self_paced' || get(nextClass, `${program.id}.is_self_paced`)">
          <b-badge
            variant="secondary"
            class="mt-2 d-flex justify-content-center"
            style="font-size: 0.81rem ; width:110px"
          >
            Self Paced
          </b-badge>
        </div>
      </div>
      <div
        class="row"
        :class="{
          'justify-content-center': get(this.nextClass, `${this.program.id}.is_self_paced`) || program.hide_tuition_fee,
        }"
      >
        <div
          v-if="!program.hide_tuition_fee"
          class="d-flex justify-content-center col-6 align-items-center py-2"
          :class="{ 'border-right border-secondary': !get(this.nextClass, `${this.program.id}.is_self_paced`) }"
        >
          <div class="d-flex flex-column">
            <span class="card-title">{{ get(getCurrentSchool, 'ttl_tuition_name') || 'Total Tuition' }}</span>
            <span class="text-center">{{ $n(totalTuition, 'currency', 'en-US') }}</span>
          </div>
        </div>
        <div
          v-if="program.duration_type && !get(this.nextClass, `${this.program.id}.is_self_paced`)"
          class="d-flex justify-content-center col-6 align-items-center"
        >
          <div class="d-flex flex-column">
            <span class="card-title">{{ $t(`durationTypesEn.${program.duration_type}`) }}</span>
            <span class="text-center"> {{ program.duration }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="card-footer bg-light" style="font-size: 14px">
      <b-skeleton-wrapper :loading="isLoading">
        <template #loading>
          <div class="d-flex justify-content-center mt-2">
            <b-skeleton width="60%"></b-skeleton>
          </div>
        </template>
        <div class="d-flex justify-content-center">
          <span
            v-if="get(this.nextClass, `${this.program.id}.is_self_paced`) || get(program, 'prgType') === 'referred'"
            :style="
              `background:rgb(${rgbPrimaryColor.r}, ${rgbPrimaryColor.g}, ${rgbPrimaryColor.b}, 30%); border-radius: 6px; padding: 0.5px 15px;`
            "
          >
            Enroll Today
          </span>
          <span
            class="text-break font-weight-bold"
            :style="
              `background:rgb(${rgbPrimaryColor.r}, ${rgbPrimaryColor.g}, ${rgbPrimaryColor.b}, 30%); border-radius: 6px; padding: 0.5px 15px;`
            "
            v-else-if="
              get(this.nextClass, `${this.program.id}.incoming_class`) &&
                !get(this.nextClass, `${this.program.id}.is_self_paced`)
            "
          >
            Next Class on {{ formatDateSimple(get(this.nextClass, `${this.program.id}.incoming_class`)) }}
          </span>
          <span class="text-muted text-break" v-else>
            <a class="text-secondary" @click.stop="openInfoModal"> Request More Information </a>
          </span>
        </div>
      </b-skeleton-wrapper>
    </div>
    <info-modal :showModal="showInfoModal" :program="{ id: program.id }" @close="hideInfoModal" />
  </div>
</template>

<script>
import { get } from 'lodash';
import { formatDateSimple } from '@/common/utils';
import { hexToRgb } from '../../../common/utils';
import InfoModal from './InfoModal/InfoModal.vue';
import { mapGetters } from 'vuex';
export default {
  props: {
    program: { type: Object, default: null },
    nextClass: { type: Object, default: null },
  },
  components: { InfoModal },
  data() {
    return {
      isLoading: false,
      showInfoModal: false,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    rgbPrimaryColor() {
      return hexToRgb(get(this.getCurrentSchool, 'primary_color', '#214e6f'));
    },
    totalTuition() {
      return get(this.program, 'tuition_fee', 0) + get(this.program, 'application_fee', 0);
    },
  },

  methods: {
    get,
    openInfoModal() {
      this.showInfoModal = true;
    },

    hideInfoModal() {
      this.showInfoModal = false;
    },
    formatDateSimple,
    openProgramDetail() {
      if (get(this.program, 'prgType') === 'referred') {
        // open in new tab
        window.open(
          `${get(this.program, 'school.subdomain.subdomain_url')}/programs/${this.program.slug}?affid=${get(
            this.getCurrentSchool,
            'affiliation_id',
            ''
          )}`,
          '_blank'
        );
      } else {
        this.$router.push({ name: 'program-detail', params: { slug: this.program.slug } });
      }
    },
  },

  async mounted() {},
};
</script>

<style></style>
