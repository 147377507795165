<template>
  <div>
    <b-link v-b-toggle="`schedule-section-${faq.id}`">
      <span>#{{ index }}: {{ faq.question }}</span>
      <md-icon class="accordion__toggle-icon">
        {{ faqVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
      </md-icon>
    </b-link>

    <b-collapse
      :id="`schedule-section-${faq.id}`"
      :accordion="`accordian-section-${faq.id}`"
      :ref="`schedule-collapse-${faq.id}`"
      :visible="faqVisible"
      class="accordion__menu"
      @hide="faqVisible = false"
      @show="faqVisible = true"
    >
      <div class="mt-2" style="font-size: 14px">
        <div class="flex">
          <div class="ml-3" v-safe-html="faq.answer" />
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    faq: { type: Object, default: null },
    index: { type: Number },
  },
  data() {
    return {
      faqVisible: false,
      windowWidth: window.innerWidth,
    };
  },
};
</script>

<style>
</style>