<template>
  <div style="" id="page-container">
    <div class="container">
      <b-btn
        v-if="totalScholarships"
        @click.prevent="openInfoModal"
        style="font-size: 17px"
        type="button"
        class="btn btn-secondary w-100 mb-4"
        target="_blank"
      >
        Inquire us for sponsorships
      </b-btn>
      <page-separator
        :title="
          !isLoading
            ? `showing ${
                totalScholarships ? `${pageFromCount}-${totalScholarships}` : 0
              } of ${totalScholarships} Sponsorships`
            : ''
        "
      />

      <div class="card border-left-0" v-if="!totalScholarships && !isLoading">
        <div class="card-body">
          <div class="d-flex">
            <div class="flex">
              <div class="row card-group-row home-card public-schedule-card">
                <i
                  >There isn't any sponsorship open right now.
                  <a class="text-primary" href="#" @click.prevent="openInfoModal">
                    Feel free to inquire about our upcoming ones.
                  </a>
                </i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b-skeleton-wrapper :loading="isLoading" v-else>
        <template #loading>
          <div class="row card-group-row mb-lg-12pt home-card">
            <div class="col-sm-12 card-group-row__col" v-for="item in [1, 2]" :key="item">
              <div class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <a href="#" @click.prevent class="card-title flex"> <b-skeleton width="100%"></b-skeleton></a>
                </div>
                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <a
                            href="#"
                            @click.prevent
                            class="avatar avatar-xl overlay js-overlay overlay--primary rounded-circle p-relative o-hidden mb-16pt align-self-center"
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <b-skeleton-img class="avatar-img" width="160" height="100%"></b-skeleton-img>
                          </a>
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <b-skeleton width="100%"></b-skeleton>
                            <b-skeleton width="55%"></b-skeleton>
                            <b-skeleton width="70%"></b-skeleton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div class="row card-group-row mb-lg-12pt home-card">
          <router-link
            :to="`/sponsorships/${scholarship.id}?program=${program.slug}`"
            v-for="scholarship in scholarships"
            :key="scholarship.id"
            style="width: 100%"
          >
            <div class="col-sm-12 card-group-row__col clickable-item">
              <div class="card card-lg overlay--primary-dodger-blue card-group-row__card">
                <div class="card-header d-flex align-items-center">
                  <span class="card-title flex mr-12pt">{{ scholarship.title }}</span>
                  <span>
                    <i class="material-icons icon-16pt mr-1 text-dark">business</i>

                    <span v-if="get(scholarship, 'posted_by_org')">{{ scholarship.posted_by_org.name }}</span>
                    <span v-for="emp in scholarship.employers" :key="emp.id" v-else>{{ emp.name }}</span>
                  </span>
                </div>

                <div class="card-body d-flex flex-column">
                  <div class="d-flex align-items-center">
                    <div class="flex">
                      <div class="mb-16pt" :class="{ 'd-flex align-items-center': !isMobSmallScreen }">
                        <div class="mr-12pt" :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen }">
                          <a
                            href="#"
                            @click.prevent
                            class="avatar avatar-xl overlay js-overlay overlay--primary rounded p-0 o-hidden d-flex justify-content-center align-items-center"
                            style="border: 1px solid #dfe2e6;id #dfe2e6;id #dfe2e6;pxl"
                          >
                            <img
                              class="avatar-img m-0 rounded"
                              :src="scholarship.image_url ? scholarship.image_url : DefaultAvatar"
                              width="160"
                              height="auto"
                            />
                          </a>
                        </div>
                        <div class="flex">
                          <div class="mb-0" style="text-transform: none">
                            <div v-if="scholarship.min_amount && scholarship.max_amount">
                              <i class="material-icons icon-16pt">attach_money</i> Amount:
                              <strong style="font-weight: bold"
                                >${{ scholarship.min_amount }} - ${{ scholarship.max_amount }}</strong
                              >
                            </div>
                            <div v-if="scholarship.deadline">
                              <i class="material-icons icon-16pt">calendar_today</i> Deadline:
                              <strong style="font-weight: bold">{{ formatFullDate(scholarship.deadline) }}</strong>
                            </div>
                            <div>
                              <i class="material-icons icon-16pt">bookmark_border</i> Category:
                              <strong style="font-weight: bold">{{
                                $t(`sponsorshipCategories.${scholarship.categories}`)
                              }}</strong>
                            </div>
                            <div v-if="scholarship.work_commitment !== 0">
                              <i class="material-icons icon-16pt">work_outline</i> Work Commitment:
                              <strong style="font-weight: bold">{{ scholarship.work_commitment }} Months</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-button">
                    <div class="mb-3">
                      {{ scholarship.summary }}
                    </div>

                    <div class="mb-2">
                      <b-btn
                        :to="`/sponsorships/${scholarship.id}?program=${program.slug}`"
                        style="height: 40px; width: 100%"
                        variant="primary"
                      >
                        <i class="material-icons icon-16pt mr-1">launch</i>Apply Now
                      </b-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </b-skeleton-wrapper>

      <pagination
        v-if="totalScholarships > scholarships.length"
        v-model="currentPage"
        :total-rows="totalScholarships"
        :per-page="perPage"
        @change="onPageChange"
        aria-controls="scholarships-table"
      />
    </div>
    <info-modal
      :types="'scholarship'"
      :scholarships="scholarships"
      :program="program"
      :showModal="showInfoModal"
      @close="hideInfoModal"
    />
  </div>
</template>

<script>
import { debounce, get } from 'lodash';
import Pagination from '@/components/Ui/Pagination.vue';
import { mapActions, mapGetters } from 'vuex';
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { USER_ROLES, SPONSORSHIP_CATEGORIES_TYPES } from '@/common/constants';

import { formatFullDate } from '@/common/utils';
import InfoModal from '../InfoModal/InfoModal.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
export default {
  name: 'ScholarshipsListing',
  components: { Pagination, InfoModal, PageSeparator },

  props: {
    program: { type: Object, default: null },
  },
  data() {
    return {
      DefaultAvatar,

      isLoading: true,
      perPage: 5,
      currentPage: 1,
      totalScholarships: 0,
      scholarships: [],
      searchTerm: '',
      ordering: '-id',
      pageFromCount: 0,

      windowWidth: window.innerWidth,
      firstLoad: true,
      USER_ROLES,
      showInfoModal: false,
      transitionMainUrl: process.env.VUE_APP_TRANSITION_MAIN_URL,
      SPONSORSHIP_CATEGORIES_TYPES,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),

    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },

  watch: {},

  methods: {
    ...mapActions('program', ['getAllScholarshipList']),
    get,
    formatFullDate,

    async fetchScholarships(pageNum = 1, params = {}) {
      this.isLoading = true;

      try {
        const response = await this.getAllScholarshipList({
          limit: this.perPage,
          offset: (pageNum - 1) * this.perPage,
          ...(this.ordering && { ordering: this.ordering }),
          ...(this.searchTerm && { search: this.searchTerm }),
          program_id: this.program.id,
          is_active: true,
          ...params,
        });

        this.scholarships = response.data.results;

        this.currentPage = pageNum;

        this.totalScholarships = response.data.count;
        this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;

        this.$emit('totalScholarships', this.totalScholarships);
      } catch (err) {
        this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }

      this.isLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchScholarships(pageNum);
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchScholarships();
    },
    openInfoModal() {
      this.showInfoModal = true;
    },
    hideInfoModal() {
      this.showInfoModal = false;
    },
    onSearch() {
      this.debouncedSearchScholarships(this);
    },

    debouncedSearchScholarships: debounce((vm) => {
      vm.fetchScholarships();
    }, 500),

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.fetchScholarships();
    this.firstLoad = false;
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>