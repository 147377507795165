<template>
  <div>
    <div>
      <div class="row card-group-row mb-lg-12pt home-card public-schedule-card">
        <div class="col-sm-12 mt-2" v-for="(classSch, index) in classes" :key="classSch.id">
          <schedule-card :classSch="classSch" :program="program" :index="index + 1" />
          <router-link
            style="font-size: 17px"
            type="button"
            class="btn btn-primary w-100"
            :to="{
              name: program.pre_enrollment_enabled ? 'class-pre-enrollment' : 'class-registration',
              query: { class: classSch.id, ...($route.query.affid && { affid: $route.query.affid }) },
            }"
            target="_blank"
          >
            <i class="fas fa-edit mr-2"></i>Register
          </router-link>
          <page-separator title="" class="mt-2" />
        </div>
      </div>

      <div class="card card-block card-stretch card-height col-md-12" v-if="!classes.length && !areClassesLoading">
        <div class="card-body text-center">
          <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
          <h4>No Class Offered</h4>

          <p class="text-muted">No classes for this program are being offered right now. Please check again later.</p>
        </div>
      </div>

      <b-skeleton-wrapper :loading="areClassesLoading" v-else>
        <template #loading>
          <div v-for="item in [1, 2, 3]" :key="item">
            <b-card>
              <b-skeleton width="85%"></b-skeleton>
              <b-skeleton width="55%"></b-skeleton>
              <b-skeleton width="70%"></b-skeleton>
            </b-card>
            <b-skeleton class="mb-5" width="100%" type="button"> </b-skeleton>
          </div>
        </template>
      </b-skeleton-wrapper>
    </div>
    <info-modal :types="'scholarship'" :program="program" :showModal="showInfoModal" @close="hideInfoModal" />
  </div>
</template>

<script>
import ScheduleCard from './ScheduleCard.vue';
import InfoModal from '../InfoModal/InfoModal.vue';
import PageSeparator from '@/components/Ui/PageSeparator.vue';
export default {
  components: { ScheduleCard, InfoModal, PageSeparator },
  data() {
    return {
      showInfoModal: false,
    };
  },
  props: {
    classes: {
      type: Array,
      default() {
        return [];
      },
    },

    program: { type: Object, default: null },
    areClassesLoading: { type: Boolean, default: false },
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
  },
  methods: {
    openInfoModal() {
      this.showInfoModal = true;
    },
    hideInfoModal() {
      this.showInfoModal = false;
    },
    openRegistrationForm(data) {
      let routeData = this.$router.resolve({
        name: 'class-registration',
        params: { program: this.program, classes: data },
      });
      window.open(routeData.href, '_blank');
    },
  },
  mounted() {},
};
</script>

<style></style>
