<template>
  <b-modal ref="cartModal" hide-footer title="Cart" centered scrollable @hide="hideModal" size="lg">
    <div class="d-flex align-items-center">
      <div class="flex" v-if="cartData.length > 0">
        <b-table
          :fields="tableColumns"
          :items="cartData"
          head-variant="light"
          class="table-nowrap"
          hover
          responsive
          no-local-sorting
        >
          <template #cell(product_title)="data">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" no-link slot="aside">
                <b-img
                  :src="data.item.product_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.product_url"
                />
                <i class="fas fa-bag-shopping" v-else></i>
              </fmv-avatar>
              {{ data.item.product_title }}
            </div></template
          >
          <template #cell(price)="data">{{ $n(data.item.price, 'currency', 'en-US') }}</template>
          <template #cell(quantity)="data">
            <a href="#" class="text-danger" @click.prevent="decrementQuantity(data.index, data.item.quantity)">
              <i class="fas fa-minus-square"></i>
            </a>

            {{ $n(data.item.quantity) }}
            <a href="#" class="text-primary mr-2" @click.prevent="incrementQuantity(data.index)">
              <i class="fas fa-plus-square"></i>
            </a>
          </template>

          <template #cell(amount)="data">{{ $n(data.item.price * data.item.quantity, 'currency', 'en-US') }}</template>

          <template #head(actions)="">
            <span></span>
          </template>
          <template #cell(actions)="data">
            <a href="#" @click.prevent="deleteProduct(data.item.id)" v-b-popover.hover.right :title="'Delete Product'">
              <i class="material-icons text-danger">delete</i>
            </a>
          </template>
        </b-table>
      </div>
      <i v-else> No items added in cart</i>
    </div>
  </b-modal>
</template>

<script>
import DefaultAvatar from '@/assets/images/logos/logo_icon.png';
import { FmvAvatar } from 'fmv-avatar';
export default {
  components: { FmvAvatar },
  props: {
    showModal: { type: Boolean, default: false },
    cartArr: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      DefaultAvatar,
    };
  },
  computed: {
    tableColumns() {
      return [
        { key: 'product_title', label: 'Product' },
        { key: 'price', label: 'Price' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'amount', label: 'Amount' },
        { key: 'size', label: 'Size' },
        {
          key: 'actions',
          tdClass: 'text-right',
          thStyle: { minWidth: '100px' },
        },
      ];
    },
    cartData() {
      return this.cartArr;
    },
  },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.cartModal.show();
      }
    },
  },
  methods: {
    addToCart() {
      this.$emit('add', this.product);
    },
    incrementQuantity(index) {
      this.$emit('increase', index);
    },

    decrementQuantity(index, quantity) {
      if (quantity > 1) {
        this.$emit('decrease', index);
      }
    },
    deleteProduct(id) {
      this.makeToast({ variant: 'success', msg: 'Product removed from cart.' });
      this.$emit('remove', id);
    },
    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.cartModal.hide();
      this.hideModal();
    },
  },
};
</script>

<style>
</style>
