<template>
  <b-modal
    ref="appTermsModal"
    hide-footer
    title="Enroll For Program"
    centered
    lazy
    scrollable
    @hide="hideModal"
    :size="size"
  >
    <b-skeleton-wrapper :loading="isLoading">
      <template #loading>
        <div class="mb-2">
          <b-skeleton width="60%"></b-skeleton>
          <b-skeleton width="80%"></b-skeleton>
        </div>
      </template>

      <div class="">
        <div class="text-center">
          This sponsorship is being offered to the students who register for a program offered by
          {{ get(getCurrentSchool, 'name') }}. If you want to apply for this sponsorship enroll now.
        </div>
        <div class="mt-2 d-flex justify-content-center">
          <b-btn
            variant="primary"
            :disabled="isLoading"
            class="mt-2 mr-4 btn-normal"
            :to="{
              name: 'program-detail',
              params: { slug: this.$route.query.program },
              query: { tab: 'upcoming-classes' },
            }"
          >
            <md-icon class="icon-16pt mr-1">launch</md-icon>Enroll Now
          </b-btn>
        </div>
      </div>
    </b-skeleton-wrapper>
  </b-modal>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  name: 'AppTermsModal',

  props: {
    showModal: { type: Boolean, default: false },
    size: { type: String, default: 'lg' },
    scholarship: { type: Object, default: () => {} },
  },

  data() {
    return {
      isLoading: false,
      school: {},
    };
  },

  computed: { ...mapGetters('school', ['getCurrentSchool']) },

  watch: {
    showModal(value) {
      if (value) {
        this.$refs.appTermsModal.show();
      }
    },
  },

  methods: {
    get,

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.appTermsModal.hide();
      this.hideModal();
    },
  },

  async mounted() {},
};
</script>
