<template>
  <div class="card card-lg overlay--primary-dodger-blue card-group-row__card">
    <div class="card-body d-flex flex-column">
      <div class="d-flex align-items-center">
        <div class="flex">
          <div :class="{ 'd-flex ': !isMobSmallScreen }">
            <div :class="{ 'd-flex justify-content-center mb-3': isMobSmallScreen, 'mr-16pt': !isMobSmallScreen }">
              <user-avatar slot="aside" :user="instructor" size="lg" :file-url="instructor.photo" variant="dark">
              </user-avatar>
            </div>
            <div class="flex">
              <div class="mb-0" style="text-transform: none">
                <h3 class="mb-2">{{ instructor.first_name }} {{ instructor.last_name }}.</h3>
                <div v-safe-html="get(instructor, 'bio', '')" class="mb-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { get } from 'lodash';
import UserAvatar from '../../../../components/User/UserAvatar.vue';
export default {
  props: {
    instructor: { type: Object, default: null },
    program: { type: Object, default: null },
    index: { type: Number },
  },
  components: {
    UserAvatar,
  },

  methods: {
    get,
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
};
</script>

<style>
</style>