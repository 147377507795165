<template>
  <div>
    <div class="d-flex justify-content-center mt-2 pt-2">
      <b-img :src="get(getCurrentSchool, 'logo_url')" height="110" />
    </div>
    <div class="d-flex justify-content-center mt-2 text-center">
      <h3 class="mb-0 font-weight-bolder" v-text="`${get(classSch, 'program.title')} Registration`" />
    </div>
    <div class="mx-3 mt-3 mb-4 text-center">
      <span v-if="get(getCurrentSchool, 'pp_products_desc')" style="font-size: 0.9125rem">
        {{ get(getCurrentSchool, 'pp_products_desc') }}
      </span>
      <span v-else style="font-size: 0.9125rem">
        View and add additional merchandise, uniforms, and supplies from our invetory of products below. All orders will
        be received on the date of class orientation.
      </span>
    </div>

    <b-form class="col-md-12 px-0 page-section pt-0 pb-5 mb-5 mt-2" @submit.prevent="pay">
      <div class="page-section d-flex justify-content-end mr-5">
        <a class="clickable-item d-flex align-items-start" @click.prevent="openCartModal">
          <md-icon style="font-size: 2rem">shopping_cart</md-icon>
          <b-badge v-if="cartArr.length" pill variant="accent badge-notifications">
            {{ cartArr.length }}
          </b-badge>
        </a>
      </div>
      <div class="page-section d-flex justify-content-center" v-if="!products.length && !areProductsLoading">
        <div class="card card-block card-stretch card-height col-md-8">
          <div class="card-body text-center">
            <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
            <h4>No Products Found</h4>

            <p class="text-muted">Couldn't find any product. Come back later.</p>
          </div>
        </div>
      </div>

      <b-skeleton-wrapper :loading="areProductsLoading" v-else>
        <template #loading>
          <div class="row">
            <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
              <div class="card card-sm card--elevated p-relative">
                <span class="js-image">
                  <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                </span>

                <div class="card-body">
                  <div class="d-flex">
                    <div class="flex">
                      <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                      <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                    </div>
                    <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                      >more_vert</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>

        <div class="row card-group-row mb-lg-12pt home-card">
          <div v-for="pro in products" :key="pro.id" class="col-lg-4 col-md-4 col-sm-6">
            <div class="card card-sm card--elevated p-relative overlay clickable-item">
              <span
                class="js-image d-flex justify-content-center clickable-item"
                data-position="left"
                :data-height="168"
                @click.prevent="openDetailModal(pro)"
              >
                <img :src="pro.product_url" alt="cna" class="career-card-img" />
              </span>

              <div class="card-body">
                <div class="d-flex">
                  <a class="flex" href="#" @click.prevent="openDetailModal(pro)">
                    <div class="card-title">{{ pro.product_title }}</div>
                    <span>{{ $n(pro.price, 'currency', 'en-US') }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-skeleton-wrapper>
      <div class="d-flex justify-content-between">
        <b-btn
          variant="light"
          style="width: 150px"
          :disabled="isPayLoading"
          @click.prevent="backToForm"
          class="btn-normal float-right"
        >
          <span>Back</span>
        </b-btn>

        <b-btn
          variant="primary"
          style="width: 150px"
          @click.prevent="continueToDetails"
          :disabled="isPayLoading || isFormLoading || isPaymentDetailsLoading"
          class="btn-normal float-right"
        >
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Continue</span>
        </b-btn>
      </div>
    </b-form>
    <product-detail-modal
      :show-modal="showProductDetail"
      :product="product"
      @close="hideDetailModal"
      @add="addToCart"
      @increase="incrementQuantity"
      @decrease="decrementQuantity"
    />
    <cart-modal
      :show-modal="showCartModal"
      :cart-arr="cartArr"
      @close="hideCartModal"
      @remove="removeFromCart"
      @increase="incrementQuantity"
      @decrease="decrementQuantity"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { get } from 'lodash';
import ProductDetailModal from './ProductDetailModal.vue';
import CartModal from './CartModal.vue';
export default {
  name: 'ProductCard',
  components: {
    ProductDetailModal,
    CartModal,
  },
  props: {
    classSch: { type: Object, default: null },
    areProductsLoading: { type: Boolean, default: false },
    cartArr: { type: Array, default: () => [] },
    products: { type: Array, default: () => [] },
    isPayLoading: { type: Boolean, default: false },
    isFormLoading: { type: Boolean, default: false },
    isPaymentDetailsLoading: { type: Boolean, default: false },
    isLoading: { type: Boolean, default: false },
  },
  data() {
    return {
      quantity: 0,
      product: {},
      showProductDetail: false,
      showCartModal: false,
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
  },
  methods: {
    get,
    addToCart(obj, quantity, size) {
      this.$emit('updateCart', obj, quantity, size);
    },
    removeFromCart(id) {
      this.$emit('remove', id);
    },
    openCartModal() {
      this.showCartModal = true;
    },
    incrementQuantity(index) {
      this.$emit('increase', index);
    },
    decrementQuantity(index) {
      this.$emit('decrease', index);
    },
    hideCartModal() {
      this.showCartModal = false;
    },
    openDetailModal(data) {
      this.showProductDetail = true;
      this.product = data;
    },
    hideDetailModal() {
      this.showProductDetail = false;
      this.product = {};
    },
    backToForm() {
      this.$emit('back');
    },
    continueToDetails() {
      this.$emit('continue');
    },
  },
};
</script>

<style>
</style>
