<template>
  <div class="card">
    <div class="card-header bg-light">
      <h5 class="my-0">Product Details</h5>
    </div>
    <div class="card-body d-flex align-items-center">
      <div class="w-100">
        <b-table
          :fields="tableColumns"
          :items="cartArr"
          head-variant="light"
          class="table-nowrap"
          hover
          responsive
          no-local-sorting
        >
          <template #cell(product_title)="data">
            <div class="d-flex align-items-center">
              <fmv-avatar :title="true" title-class="bg-transparent" no-link slot="aside">
                <b-img
                  :src="data.item.product_url"
                  class="img-fluid"
                  width="40"
                  alt="Logo"
                  v-if="data.item.product_url"
                />
                <i class="fas fa-bag-shopping" v-else></i>
              </fmv-avatar>
              {{ data.item.product_title }}
            </div></template
          >

          <template #cell(price)="data"> {{ $n(data.item.price, 'currency', 'en-US') }}</template>
          <template #cell(quantity)="data">{{ $n(data.item.quantity) }}</template>
          <template #cell(amount)="data"> {{ $n(data.item.quantity * data.item.price, 'currency', 'en-US') }}</template>

          <template v-slot:custom-foot>
            <tr>
              <th>Total</th>
              <th />
              <th />
              <th />
              <th>{{ $n(total, 'currency', 'en-US') }}</th>
            </tr>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import { FmvAvatar } from 'fmv-avatar';
export default {
  name: 'ProductCheckoutCard',
  props: {
    cartArr: { type: Array, default: () => [] },
  },
  components: {
    FmvAvatar,
  },
  computed: {
    tableColumns() {
      return [
        { key: 'product_title', label: 'Product' },
        { key: 'price', label: 'Price' },
        { key: 'quantity', label: 'Quantity' },
        { key: 'size', label: 'Size' },
        { key: 'amount', label: 'Amount' },
      ];
    },

    total() {
      return this.cartArr.reduce((total, item) => item.price * item.quantity + total, 0);
    },
  },
  async mounted() {},
};
</script>
