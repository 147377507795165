<template>
  <div class="page-section program-card mt-0 pt-0 mb-32pt">
    <div class="row">
      <div :class="get(program, 'video_link') ? 'col-md-6' : 'col-12'">
        <div class="card border-left-0">
          <div class="card-body">
            <div class="d-flex mb-1">
              <div>
                <div class="d-flex align-items-center mb-1">
                  <h5 class="card-title fw600">Features</h5>
                </div>

                <div class="d-flex">
                  <div class="flex">
                    <h6 v-if="program.duration_type && get(program, 'schedule_type') !== 'self_paced'" class="">
                      <i class="fas fa-calendar-week icon-16pt mr-8pt"></i>Program
                      {{ $t(`durationTypesEn.${program.duration_type}`) }}: {{ program.duration }}
                    </h6>
                    <h6 class="">
                      <i class="fas fa-stream icon-16pt mr-8pt"></i>Learn Method:
                      {{ $t(`programTypeOptions.${program.type}`) }}
                    </h6>
                    <div v-if="!program.hide_tuition_fee">
                      <h6 class="">
                        <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                        >{{ get(getCurrentSchool, 'app_fee_name') || 'Application Fee' }}:
                        {{ $n(program.application_fee, 'currency', 'en-US') }}
                      </h6>
                      <h6 class="">
                        <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                        >{{ get(getCurrentSchool, 'tuition_fee_name') || 'Tuition Fee' }}:
                        {{ $n(program.tuition_fee, 'currency', 'en-US') }}
                      </h6>

                      <h6 class="">
                        <i class="fas fa-dollar-sign icon-16pt mr-8pt"></i
                        >{{ get(getCurrentSchool, 'ttl_tuition_name') || 'Total Tuition ' }}:
                        {{ $n(totalTuition, 'currency', 'en-US') }}
                      </h6>
                    </div>
                  </div>
                </div>
                <!--d-flex -->

                <div></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="get(program, 'video_link')"
        :class="get(program, 'video_link') ? 'col-md-6' : ''"
        class="clickable-item video-card mb-3"
        style="border-left: none"
        @click.prevent="handleVideoOpen"
      >
        <video
          width="100%"
          height="222px"
          autoplay
          loop
          muted
          style="object-fit: cover; border-radius: 10px; box-shadow: 0 3px 3px #00000026"
        >
          <source :src="get(program, 'video_link')" type="video/mp4" />
        </video>
        <a class="play-btn clickable-item" @click.prevent="handleVideoOpen">
          <md-icon style="font-size: 80px; color: ">play_arrow</md-icon>
        </a>
      </div>
    </div>

    <div class="card border-left-0">
      <div class="card-body">
        <div class="d-flex mb-1">
          <div class="flex">
            <div class="d-flex align-items-center mb-1">
              <h5 class="card-title fw600">Description</h5>
            </div>

            <div class="d-flex">
              <div class="flex program-description">
                <div v-safe-html="get(program, 'overview', '')" />
              </div>
            </div>

            <div></div>
          </div>
        </div>
      </div>
    </div>
    <promo-video-modal :showModal="videoModal" @close="hideVideoModal" :url="get(program, 'video_link')" />
  </div>
</template>

<script>
import { get } from 'lodash';
import { mapGetters } from 'vuex';
import PromoVideoModal from './VideoModal/PromoVideoModal.vue';
export default {
  props: {
    program: { type: Object, default: null },
  },
  components: {
    PromoVideoModal,
  },
  data() {
    return {
      videoModal: false,
    };
  },
  methods: {
    get,
    handleVideoOpen() {
      this.videoModal = true;
    },
    hideVideoModal() {
      this.videoModal = false;
    },
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    totalTuition() {
      return get(this.program, 'tuition_fee', 0) + get(this.program, 'application_fee', 0);
    },
  },
};
</script>

<style></style>
