<template>
  <div style="margin-top: -18px" :class="isMobSmallScreen ? '' : 'container'">
    <div
      class="bg-primary page-section employer-header-bg border-bottom-white mb-10pt img-fluid"
      :style="`background-image: url('${get(getCurrentSchool, 'profile_bg_img_url')}');`"
    >
      <div
        class="container page__container mb-0 d-flex align-items-center justify-content-center h-100"
        :style="`background:(rgba(${rgbDarkColor.r}, ${rgbDarkColor.g}, ${rgbDarkColor.b}, 0.95) !important;`"
      >
        <div class="text-center mb-1" v-if="get(getCurrentSchool, 'show_banner_status')">
          <h1 class="text-white mb-8pt employer-header-h1" style="">
            {{ get(getCurrentSchool, 'pp_home_title') }}
          </h1>
          <p class="measure-lead mx-auto text-white d-lg-block employer-header-text mb-0">
            {{ get(getCurrentSchool, 'pp_home_description') }}
          </p>
        </div>
      </div>
    </div>

    <public-programs :pageContainer="isMobileSmallScreen()" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { get } from 'lodash';
import PublicPrograms from '../Programs/PublicPrograms.vue';
import Page from '@/components/Page.vue';
import { hexToRgb } from '../../../common/utils';

export default {
  extends: Page,
  name: 'SchlPublicHome',

  components: { PublicPrograms },

  head() {
    return {
      title: `${get(this.getCurrentSchool, 'name', 'Home')} - Offered Programs`,
      meta: [
        {
          name: 'keywords',
          content: (get(this.getCurrentSchool, 'meta_tags', []) || []).toString(),
        },
        {
          name: 'description',
          content: get(this.getCurrentSchool, 'pp_home_description', ''),
        },
      ],
    };
  },

  data() {
    return {
      title: 'Home',
      completeHeadTitle: 'Home - Transition Enroll',

      firstLoad: true,
      windowWidth: window.innerWidth,
    };
  },

  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [{ text: this.$t('home'), active: true }];
    },
    rgbDarkColor() {
      return hexToRgb(get(this.getCurrentSchool, 'dark_color', '#214e6f'));
    },
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 576;
    },
  },

  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.completeHeadTitle = `${get(this.getCurrentSchool, 'name', 'Home')} - ${get(
            this.getCurrentSchool,
            'pp_home_title',
            'Transition Enroll'
          )}`;
        }
      },
    },
  },

  methods: {
    ...mapActions('settings', ['setSettings']),
    get,
    onTabsChanged() {
      if (!this.firstLoad) {
        document.getElementById('homePageTab').scrollIntoView({ behavior: 'smooth' });
      }
      this.firstLoad = false;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    isMobileSmallScreen() {
      return this.isMobSmallScreen ? 'container page__container' : '';
    },
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);

    this.setSettings({ layout: { layout: 'fixed' } });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
<style scoped lang="scss">
@media (max-width: 1200px) {
  .image-container {
    padding: 0 !important;
  }
}
</style>
