<template>
  <b-modal
    ref="joinWaitlistModal"
    hide-footer
    title="Join Program Waitlist"
    centered
    lazy
    scrollable
    @hide="hideModal"
    size="lg"
    class="bg-light"
  >
    <p class="text-muted" style="">
      Complete the form below to get notified as soon as we start offering classes for this program.
    </p>
    <b-form @submit.prevent="onSubmit">
      <div class="row">
        <b-form-group :label="$t('userMsgs.firstName')" label-for="firstname" label-class="form-label" class="col-md-6">
          <b-form-input id="firstname" :placeholder="$t('userMsgs.firstName')" v-model.trim="firstName" required />
        </b-form-group>

        <b-form-group :label="$t('userMsgs.lastName')" label-for="lastname" label-class="form-label" class="col-md-6">
          <b-form-input id="lastname" :placeholder="$t('userMsgs.lastName')" v-model.trim="lastName" required />
        </b-form-group>
      </div>
      <div class="row">
        <b-form-group :label="$t('authMsgs.emailAddr')" label-for="email" label-class="form-label" class="col-md-6">
          <b-form-input
            id="email"
            :placeholder="$t('authMsgs.emailAddr')"
            v-model="email"
            type="email"
            autocomplete="off"
            required
            :state="errorStates.userEmail"
          />
          <b-form-invalid-feedback>{{ $t('userMsgs.userAlreadyExists') }}</b-form-invalid-feedback>
        </b-form-group>
        <b-form-group :label="$t('studentMsgs.phone')" label-for="phone" label-class="form-label" class="col-md-6">
          <b-form-input
            id="phone"
            placeholder="(888) 689 - 1235"
            v-model="phone"
            v-mask="'(###) ### - ####'"
            :state="errorStates.phone"
            required
          />
          <b-form-invalid-feedback>
            Invalid phone number. Please make sure that it's in correct format i.e. (XXX) XXX - XXXX.
          </b-form-invalid-feedback>
        </b-form-group>
      </div>

      <div class="d-flex justify-content-end">
        <b-btn variant="primary" :disabled="isLoading" type="submit" style="width: 150px" class="btn-normal">
          <i v-if="isLoading" class="fas fa-circle-notch fa-spin"></i>
          <span v-else>Submit Request</span>
        </b-btn>
      </div>
    </b-form>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { get } from 'lodash';
import { PHONE_FORMAT_REGEX } from '@/common/constants';
import { titleize } from '@/common/utils';

export default {
  name: 'JoinWaitlistModal',

  props: {
    showModal: { type: Boolean, default: false },
    program: { type: Object, default: null },
  },

  data() {
    return {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',

      errorStates: {
        userEmail: null,
        phone: null,
      },

      isLoading: false,
      school: null,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
  },

  watch: {
    showModal(value) {
      if (value) {
        this.resetData();
        this.$refs.joinWaitlistModal.show();
      }
    },

    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.school = get(value, 'id');
        }
      },
    },
  },
  methods: {
    ...mapActions('program', ['createWaitlist']),
    get,

    async onSubmit() {
      this.isLoading = true;

      if (!PHONE_FORMAT_REGEX.test(this.phone)) {
        this.errorStates.phone = false;
        this.isLoading = false;
        return;
      }

      try {
        await this.createWaitlist({
          first_name: titleize(this.firstName),
          last_name: titleize(this.lastName),
          email: this.email,
          phone: this.phone,
          program: this.program.id,
        });
        this.makeToast({ variant: 'success', msg: 'Request Sent!' });
        this.hideModalManual();
      } catch (err) {
        if (err.response.status === 400) {
          this.makeToast({ variant: 'danger', msg: 'Your email already exists in the waiting list.' });
        } else this.makeToast({ variant: 'danger', msg: this.$t('generalMsgs.genErrorMsg') });
      }
      this.isLoading = false;
    },

    hideModal() {
      this.$emit('close');
    },

    hideModalManual() {
      this.$refs.joinWaitlistModal.hide();
      this.hideModal();
    },

    resetData() {
      this.firstName = this.lastName = this.email = this.phone = '';
      this.permission_to_text =
        this.errorStates.userEmail =
        this.errorStates.phone =
        this.errorStates.permission_to_text =
          null;
    },
  },

  async mounted() {},
};
</script>
