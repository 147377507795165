<template>
  <div>
    <div class="card" v-if="!instructors.length">
      <div class="card-body text-center">
        <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
        <h4>No Instructors Found</h4>

        <p class="text-muted">No instructors have been added.</p>
      </div>
    </div>

    <div class="row card-group-row mb-lg-12pt home-card" v-else>
      <div class="col-sm-12 card-group-row__col" v-for="(ins, index) in instructors" :key="ins.id">
        <instructor-card :instructor="ins" :program="program" :index="index + 1" />
      </div>
    </div>
  </div>
</template>

<script>
import InstructorCard from './InstructorCard.vue';
export default {
  components: { InstructorCard },
  props: {
    instructors: {
      type: Array,
      default() {
        return [];
      },
    },
    program: { type: Object, default: null },
  },
  computed: {
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
  },
  mounted() {},
};
</script>

<style>
</style>
