<template>
  <div
    :id="`class-${classSch.id}`"
    style="scroll-margin-top: 80px"
    class="card overlay--show card-lg overlay--primary-dodger-blue card-group-row__card"
  >
    <b-link v-b-toggle="`schedule-section-${classSch.id}`">
      <div class="card-header d-flex align-items-center accordion__toggle" :class="{ 'bg-secondary': schdulesVisible }">
        <span class="card-title flex mr-12pt">#{{ index }}: {{ program.title }}</span>
        <md-icon class="accordion__toggle-icon">
          {{ schdulesVisible ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}
        </md-icon>
      </div>
    </b-link>

    <div class="card-body d-flex flex-column">
      <b-link v-b-toggle="`schedule-section-${classSch.id}`" class="text-black">
        <div class="row align-items-center" style="font-size: 14px">
          <div
            :class="{
              'd-flex justify-content-center': !isTabSmallScreen,
              'col-md-4': classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED,
              'col-md-6': classSch.class_type !== CLASS_SCHEDULE_TYPES.SCHEDULED,
            }"
          >
            <div class="icon-align" v-if="classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED">
              <i class="fas fa-calendar-alt icon-16pt mr-1"></i><b class="mr-1"> Start/End: </b
              >{{ formatDateSimple(classSch.start_date) }} to
              {{ formatDateSimple(classSch.end_date) }}
            </div>
            <div class="icon-align" v-else>
              <i class="fas fa-clipboard-list icon-16pt mr-1"></i> <b class="mr-1">Schedule: </b> <i>Self-paced</i>
            </div>
          </div>

          <div
            v-if="classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED"
            class="col-md-4"
            :class="{ 'd-flex justify-content-center': !isTabSmallScreen }"
          >
            <div class="icon-align">
              <i class="fas fa-clipboard-list icon-16pt mr-1"></i> <b class="mr-1">Schedule: </b>
              {{ $t(`scheduleTypes.${classSch.schedule_type}`) }}
            </div>
          </div>
          <div
            :class="{
              'd-flex justify-content-center': !isTabSmallScreen,
              'col-md-4': classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED,
              'col-md-6': classSch.class_type !== CLASS_SCHEDULE_TYPES.SCHEDULED,
            }"
          >
            <i class="fas fa-map-marker-alt icon-16pt mr-1"></i> <b class="mr-1">Location: </b>
            {{ partialClassLocation }}
          </div>
        </div>
      </b-link>

      <b-collapse
        :id="`schedule-section-${classSch.id}`"
        :accordion="`accordian-section-${classSch.id}`"
        :ref="`schedule-collapse-${classSch.id}`"
        :visible="schdulesVisible"
        class="accordion__menu"
        @hide="schdulesVisible = false"
        @show="schdulesVisible = true"
      >
        <div class="mt-2" style="font-size: 14px">
          <div class="flex">
            <div>
              <b-table
                :fields="classColumnsDetailTable"
                :items="classDetailTable"
                class="table-nowrap my-3"
                striped
                responsive
                no-local-sorting
              >
                <template #head(title)="">
                  <span></span>
                </template>
                <template #cell(title)="data">
                  <span>
                    <i :class="data.item.icon" class="mr-2"></i> <b>{{ data.item.title }}</b>
                  </span>
                </template>
                <template #head(value)="">
                  <span></span>
                </template>
                <template #cell(value)="data">
                  <span v-if="data.item.type === 'instructors-btn'"
                    ><a
                      href="#"
                      style="text-decoration: underline"
                      @click.prevent="openInstructorsModal(classSch.id)"
                      >{{ data.item.value }}</a
                    ></span
                  >
                  <span v-else-if="data.item.type === 'payment-plan'">
                    <span v-if="get(classSch, 'payment_plan.length')">
                      Available -
                      <a
                        href="#"
                        style="text-decoration: underline"
                        @click.prevent="openPaymentPlanModal(classSch.payment_plan)"
                      >
                        Show Payment Schedule
                      </a>
                    </span>
                    <span v-else>Unavailable</span>
                  </span>
                  <span v-else>{{ data.item.value }}</span>
                </template>
              </b-table>

              <div
                class="mt-2"
                v-if="classSch.schedules.length > 0 && classSch.class_type === CLASS_SCHEDULE_TYPES.SCHEDULED"
              >
                <h5>Class Schedule</h5>
                <b-table
                  v-if="classSch.schedules.length > 0"
                  :fields="scheduleTableColumns"
                  :items="classSch.schedules"
                  head-variant="light"
                  class="table-nowrap"
                  hover
                  responsive
                  no-local-sorting
                >
                  <template #cell(start_time)="data">
                    <span v-if="data.item.is_self_paced">
                      <i>Self-paced</i>
                    </span>
                    <span v-else>{{ formatTime(data.item.start_time) }} {{ formatTimeZone(timeZone) }}</span>
                  </template>
                  <template #cell(end_time)="data">
                    <span v-if="data.item.is_self_paced">
                      <i>Self-paced</i>
                    </span>
                    <span v-else>{{ formatTime(data.item.end_time) }} {{ formatTimeZone(timeZone) }}</span>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
    <instructor-modal :instructors="instructors" :showModal="showInstructorModal" @close="hideInstructorsModal" />
    <payment-plan-modal
      :payment-plan="selectedPaymentPlan"
      :program-tuition="get(program, 'tuition_fee', 0)"
      :showModal="showPaymentPlanModal"
      :classSelfPaced="classSelfPaced"
      @close="hidePaymentPlanModal"
    />
  </div>
</template>

<script>
import { CLASS_SCHEDULE_TYPES } from '../../../../common/constants';
import {
  formatDateSimple,
  formatTime,
  formatTimeZone,
  formatDayDate,
  formatDateFullYear,
} from '../../../../common/utils';
import { get } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import moment from 'moment-timezone';
import MdIcon from '../../../../components/Ui/MdIcon.vue';
import InstructorModal from './InstructorModal.vue';
import PaymentPlanModal from './PaymentPlanModal.vue';

export default {
  components: { MdIcon, InstructorModal, PaymentPlanModal },
  props: {
    classSch: { type: Object, default: null },
    program: { type: Object, default: null },
    index: { type: Number },
  },
  data() {
    return {
      schdulesVisible: false,
      windowWidth: window.innerWidth,
      timeZone: '',
      showInstructorModal: false,
      areInstructorsLoading: false,
      instructors: [],
      classSelfPaced: false,

      showPaymentPlanModal: false,
      selectedPaymentPlan: null,
      CLASS_SCHEDULE_TYPES,
    };
  },
  computed: {
    ...mapGetters('school', ['getCurrentSchool']),
    isTabSmallScreen() {
      return this.windowWidth <= 767;
    },
    classColumnsDetailTable() {
      return [
        {
          key: 'title',
          label: '',
          thStyle: { width: '50%' },
        },
        {
          key: 'value',
          label: '',
          thStyle: { minWidth: '50%' },
        },
      ];
    },
    classDetailTable() {
      return [
        { icon: 'fas fa-info', title: 'Class ID', value: this.classSch.id },

        {
          icon: 'fas fa-calendar-week',
          title: 'Week',
          value: this.$n(this.classSch.total_weeks, 'singleDecimal', 'en-US'),
        },
        {
          icon: 'fas fa-clock',
          title: 'Total Hours',
          value: this.$n(this.classSch.total_hours, 'singleDecimal', 'en-US'),
        },
        { icon: 'fas fa-map-marker-alt', title: 'Location', value: this.fullClassLocation },
        { type: 'instructors-btn', icon: 'fas fa-chalkboard-teacher', title: 'Instructors', value: 'Show Instructors' },
        {
          icon: 'fas fa-calendar-minus',
          title: 'Orientation Date',
          value: this.classSch.orientation_date ? formatDateSimple(this.classSch.orientation_date) : 'N/A',
        },
        ...(this.classSch.limited_seats
          ? [{ icon: 'fas fa-user', title: 'Remaining Seats', value: this.$n(this.classSch.available_seats || 0) }]
          : []),
        ...(!get(this.program, 'hide_tuition_fee')
          ? [
              {
                icon: 'fas fa-dollar-sign',
                title: get(this.getCurrentSchool, 'ttl_tuition_name') || 'Total Tuition',
                value: this.$n(this.totalTuition, 'currency', 'en-US'),
              },
            ]
          : []),
        { type: 'payment-plan', icon: 'fas fa-dollar-sign', title: 'Payment Plan', value: 'Show Payment Schedule' },
      ];
    },
    scheduleTableColumns() {
      return [
        { key: 'date', label: 'date', formatter: value => (value ? formatDayDate(value) : value) },
        {
          key: 'start_time',
          label: 'Start Time',
        },
        {
          key: 'end_time',
          label: 'End Time',
        },
      ];
    },
    fullClassLocation() {
      return get(this.classSch, 'class_location')
        ? `${this.classSch.class_location.address}, ${this.classSch.class_location.city}, ${this.classSch.class_location.state} ${this.classSch.class_location.zipcode}, ${this.classSch.class_location.country}`
        : 'N/A';
    },
    partialClassLocation() {
      return get(this.classSch, 'class_location')
        ? `${this.classSch.class_location.city}, ${this.classSch.class_location.state} ${this.classSch.class_location.zipcode}`
        : 'N/A';
    },
    totalTuition() {
      return get(this.program, 'tuition_fee', 0) + get(this.program, 'application_fee', 0);
    },
  },
  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (get(value, 'id')) {
          this.timeZone = value.time_zone;
        }
      },
    },
  },

  methods: {
    ...mapActions('instructor', ['getPublicInstructors']),
    formatDateSimple,
    formatDateFullYear,
    formatTime,
    moment,
    formatTimeZone,
    get,

    async fetchInstructors() {
      this.areInstructorsLoading = true;
      const response = await this.getPublicInstructors({
        class: this.classSch.id,
      });
      this.instructors = response.data;
      this.areInstructorsLoading = false;
    },

    async openInstructorsModal() {
      await this.fetchInstructors();
      this.showInstructorModal = true;
    },
    hideInstructorsModal() {
      this.showInstructorModal = false;
    },

    openPaymentPlanModal(paymentPlan) {
      this.classSelfPaced = this.classSch.schedules.is_self_paced;
      this.selectedPaymentPlan = paymentPlan;
      this.showPaymentPlanModal = true;
    },

    hidePaymentPlanModal() {
      this.showPaymentPlanModal = false;
      this.selectedPaymentPlan = null;
    },

    handleResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    if (this.$route.query.class_id === String(this.classSch.id)) {
      document.getElementById(`class-${this.classSch.id}`).scrollIntoView({ behavior: 'smooth' });
      setTimeout(() => {
        this.schdulesVisible = true;
      }, 500);
    }
    window.addEventListener('resize', this.handleResize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style></style>
