<template>
  <div>
    <div class="page-section">
      <div :class="containerClass">
        <div class="d-flex flex-column flex-sm-row align-items-sm-center mb-16pt" style="white-space: nowrap">
          <div class="d-flex">
            <h2 class="mb-0">All Programs</h2>

            <div class="my-auto ml-auto">
              <b-btn @click="toggleFilters" v-if="enableSidebarToggle !== false" size="sm" variant="white" class="mr-2">
                <md-icon left>tune</md-icon>
                <span>Filters</span>
              </b-btn>
            </div>
          </div>
          <div
            v-if="(isTabScreen && showFilters) || !isTabScreen"
            :style="isTabScreen ? '' : ''"
            class="mb-sm-0"
            :class="isTabScreen ? 'mt-2' : 'd-flex ml-auto'"
          >
            <div class="col-lg-auto">
              <span v-if="!isTabScreen">
                <b-btn
                  variant="white"
                  class="mr-2"
                  @click.prevent="showTypeFilters = true"
                  v-if="!showTypeFilters"
                  size="sm"
                >
                  <md-icon left>tune</md-icon>Show Filters
                </b-btn>
                <b-btn
                  variant="light"
                  class="mr-2"
                  @click.prevent="showTypeFilters = false"
                  v-if="showTypeFilters"
                  size="sm"
                >
                  <i class="fas fa-eye-slash mr-1" />Hide Filters
                </b-btn>
              </span>
            </div>
            <div :class="isTabScreen ? '' : 'd-flex ml-auto '">
              <form
                class="search-form search-form--light d-lg-inline-flex"
                :class="isTabScreen ? '' : ' mb-8pt mb-lg-0 '"
                @submit.prevent=""
                :style="isTabScreen ? '' : 'width: 266px; margin-right: 5px'"
              >
                <b-form-input placeholder="Search..." v-model="searchTerm" @input="onSearch" />
                <b-btn variant="flush" type="submit">
                  <md-icon v-text="'search'" />
                </b-btn>
              </form>
            </div>
          </div>
        </div>

        <transition name="slide">
          <div
            class="d-flex flex-wrap justify-content-end"
            v-if="(isTabScreen && showFilters) || (!isTabScreen && showTypeFilters)"
          >
            <div :class="isTabScreen ? 'col-lg-7 px-0' : ' col-sm-5 col-md-3 mb-2 px-1'">
              <v-select
                id="program-type"
                v-model="programType"
                class="form-control v-select-custom"
                placeholder="Select program type"
                label="text"
                :reduce="type => type.value"
                :options="typeOptions"
                style="height: auto"
                @input="fetchPrograms({})"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
                <template slot="option" slot-scope="option">
                  <span>{{ option.text }}</span>
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ truncate(option.text, { length: 20 }) }}
                </template>
              </v-select>
            </div>

            <div :class="isTabScreen ? 'col-lg-7 px-0 mt-2' : ' col-sm-5 col-md-3 mb-2 px-1'">
              <v-select
                id="duration-type"
                class="form-control v-select-custom"
                label="title"
                v-model="transition_program_id"
                :reduce="program => program.id"
                placeholder="Select program category"
                :options="transProgramsOptions"
                @input="fetchPrograms({})"
                multiple
                style="height: auto"
              >
                <template #search="{ attributes, events }">
                  <input class="vs__search" v-bind="attributes" v-on="events" />
                </template>
                <template slot="option" slot-scope="option">
                  <span>{{ option.title }}</span>
                </template>
                <template slot="selected-option" slot-scope="option">
                  {{ truncate(option.title, { length: 20 }) }}
                </template>
              </v-select>
            </div>
          </div>
        </transition>

        <page-separator
          :title="
            isLoading
              ? ''
              : `Showing ${totalPrograms ? `${$n(pageFromCount)}-${$n(pageToCount)}` : 0} of ${$n(
                  totalPrograms
                )} Programs`
          "
        />

        <div class="page-section d-flex justify-content-center" v-if="!programs.length && !isLoading">
          <div class="card card-block card-stretch card-height col-md-8">
            <div class="card-body text-center">
              <i class="fas fa-exclamation-circle fa-lg text-muted mt-4 mb-4" style="font-size: 3rem" />
              <h4>No Programs Found</h4>

              <p class="text-muted">Couldn't find any programs.</p>
            </div>
          </div>
        </div>

        <b-skeleton-wrapper :loading="isLoading" v-else>
          <template #loading>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6" v-for="item in [1, 2, 3]" :key="item">
                <div class="card card-sm card--elevated p-relative">
                  <span class="js-image">
                    <b-skeleton-img width="100%" class="pb-0 mb-0"></b-skeleton-img>
                  </span>

                  <div class="card-body">
                    <div class="d-flex">
                      <div class="flex">
                        <a class="card-title" href="#" @click.prevent><b-skeleton width="70%"></b-skeleton></a>
                        <small class="text-50 font-weight-bold mb-4pt"><b-skeleton width="50%"></b-skeleton></small>
                      </div>
                      <a href="#" class="ml-4pt material-icons text-20 card-course__icon-favorite" @click.prevent
                        >more_vert</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <div class="row card-group-row mb-lg-12pt home-card">
            <div v-for="program in programs" :key="program.id" class="col-lg-4 col-md-4 col-sm-6">
              <program-card :program="program" :next-class="nextClassObj" />
            </div>
          </div>
        </b-skeleton-wrapper>

        <pagination
          v-if="totalPrograms > programs.length"
          v-model="currentPage"
          :total-rows="totalPrograms"
          :per-page="perPage"
          @change="onPageChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PageSeparator from '@/components/Ui/PageSeparator.vue';
import { cloneDeep, debounce } from 'lodash';
import { mapGetters, mapActions } from 'vuex';
import Pagination from '../../../components/Ui/Pagination.vue';
import Page from '@/components/Page.vue';
import MdIcon from '../../../components/Ui/MdIcon.vue';
import { get, keyBy, truncate } from 'lodash';
import ProgramCard from './ProgramCard.vue';
import { USER_ROLES, PROGRAM_STATUSES, USER_ROLE_TYPES } from '../../../common/constants';
import vSelect from 'vue-select';
export default {
  components: { MdIcon, PageSeparator, Pagination, ProgramCard, vSelect },
  extends: Page,

  head() {
    return {
      title: `${get(this.getCurrentSchool, 'name', 'Home')} - Offered Programs`,
    };
  },
  data() {
    return {
      title: 'Programs',

      isLoading: true,
      perPage: 18,
      currentPage: 1,
      totalPrograms: 0,
      programType: '',
      programs: [],
      refPrograms: [],
      typeOptions: [
        { value: 'virtual', text: 'Virtual' },
        { value: 'onsite', text: 'Onsite' },
        { value: 'blended', text: 'Hybrid' },
      ],
      USER_ROLES,
      PROGRAM_STATUSES,
      USER_ROLE_TYPES,
      searchTerm: '',
      ordering: 'select',
      pageFromCount: 0,
      pageToCount: 0,
      nextClasses: [],
      nextClassObj: {},
      areClassesLoading: false,
      transition_program_id: null,
      transProgramsOptions: [],
      windowWidth: window.innerWidth,
      showFilters: false,
      showTypeFilters: false,
      areRefLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['getLoggedInUser']),
    ...mapGetters('school', ['getCurrentSchool']),
    breadcrumb() {
      return [
        { text: this.$t('home'), to: this.routes.home },
        { text: this.$t('Programs'), active: true },
      ];
    },
    isTabScreen() {
      return this.windowWidth <= 776;
    },
    isMobSmallScreen() {
      return this.windowWidth <= 420;
    },
    enableSidebarToggle() {
      return this.windowWidth <= 992;
    },
    areFiltersApplied() {
      return this.transition_program_id;
    },
  },
  watch: {
    getCurrentSchool: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value) {
          this.schoolId = value;

          this.fetchPrograms({ programId: this.$route.query.category_id });
          this.fetchTransPrograms();
        }
      },
    },
  },

  methods: {
    ...mapActions('program', ['getSchoolOfferedPrograms', 'getProgramNextClass', 'getSchoolReferredPrograms']),
    ...mapActions('school', ['getSchoolTransPrograms']),
    ...mapActions('settings', ['setSettings']),
    get,
    truncate,
    clearFilters() {
      this.transition_program_id = null;
      this.fetchPrograms({});
    },
    toggleFilters() {
      this.showFilters ? (this.showFilters = false) : (this.showFilters = true);
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    async fetchNextClass() {
      this.areClassesLoading = true;
      const response = await this.getProgramNextClass({
        programs: this.programs.map(cs => cs.id),
      });
      this.nextClasses = response.data;
      this.nextClassObj = keyBy(response.data, 'program');
      this.areClassesLoading = false;
    },
    async fetchTransPrograms() {
      this.areTransProgramsLoading = true;
      try {
        const response = await this.getSchoolTransPrograms({ school: get(this.getCurrentSchool, 'id') });
        if (response.data.length) {
          this.transProgramsOptions = response.data;
        } else {
          this.transProgramsOptions = [];
          this.program.transition_program_id = null;
        }
      } catch (e) {
        // TODO use if needed
      }

      this.areTransProgramsLoading = false;
    },
    async fetchPrograms({ pageNum = 1, params = {}, programId = null }) {
      this.isLoading = true;
      document.getElementById('app').scrollIntoView();

      if (pageNum == 1) {
        await this.fetchReferredPrograms();
      }

      const response = await this.getSchoolOfferedPrograms({
        limit: this.perPage,
        offset: (pageNum - 1) * this.perPage,
        ...(this.ordering != 'select' && { ordering: this.ordering }),
        ...(this.searchTerm && { search: this.searchTerm }),
        school: get(this.getCurrentSchool, 'id'),
        ...(this.programType && { type: this.programType }),
        ...params,

        transition_program_id: programId
          ? programId.toString()
          : this.transition_program_id && this.transition_program_id.length
          ? this.transition_program_id.toString()
          : null,
      });
      this.programs = response.data.results;
      this.currentPage = pageNum;
      this.totalPrograms = response.data.count;

      if (this.programs.length) {
        this.fetchNextClass();
      }
      this.pageFromCount = (this.currentPage - 1) * this.perPage + 1;
      this.pageToCount = this.pageFromCount + get(response.data, 'results.length', 0) - 1;

      // Adding referred programs after every 5th program
      const programs = cloneDeep(this.programs);
      let count = 1;
      this.programs.forEach((program, index) => {
        if ((index + 1) % 5 === 0 && this.refPrograms.length) {
          programs.splice(index + count, 0, { ...this.refPrograms.shift(), prgType: 'referred' });
          count++;
        }
      });
      this.programs = programs;
      if (this.refPrograms.length && this.pageToCount >= this.totalPrograms) {
        this.refPrograms.forEach(program => {
          this.programs.push({ ...program, prgType: 'referred' });
        });
      }

      this.isLoading = false;
    },

    async fetchReferredPrograms(params = {}) {
      this.areRefLoading = true;

      const response = await this.getSchoolReferredPrograms({
        school: get(this.getCurrentSchool, 'id'),
        ...params,
      });
      this.refPrograms = response.data;

      this.areRefLoading = false;
    },

    onPageChange(pageNum) {
      this.fetchPrograms({ pageNum: pageNum });
    },

    onSortChange(context) {
      this.ordering = context.sortDesc ? '-' + context.sortBy : context.sortBy;
      this.fetchPrograms({});
    },

    onSearch() {
      this.debouncedSearchPrograms(this);
    },

    debouncedSearchPrograms: debounce(vm => {
      vm.fetchPrograms({});
    }, 500),
  },

  async mounted() {
    window.addEventListener('resize', this.handleResize);
    this.setSettings({ layout: { layout: 'fixed' } });

    if (this.$route.query.category_id) {
      this.transition_program_id = this.$route.query.category_id.split(',').map(Number);
      this.showFilters = true;
      this.showTypeFilters = true;
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>
