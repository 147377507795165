<template>
  <div class="card border-left-0">
    <div class="card-body">
      <div class="d-flex">
        <div class="flex">
          <div class="row card-group-row home-card public-schedule-card" v-if="faqs.length > 0">
            <div class="col-sm-12" v-for="(faq, index) in faqs" :key="faq.id">
              <program-faq-card :faq="faq" :index="index + 1" :class="{ 'mt-3': index }" />
            </div>
          </div>
          <i v-else
            >There aren't any FAQs. Feel free to contact us by clicking the 'Request Information' button above.</i
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get } from 'lodash';
import ProgramFaqCard from './ProgramFaqCard.vue';
export default {
  props: {
    faqs: { type: Array, default: () => false },
  },
  components: { ProgramFaqCard },

  methods: {
    get,
  },
};
</script>

<style>
</style>
